/* .react-time-picker .wrapper {
  padding: 40px;
  border-radius: 20px;
}

.react-time-picker {
  border: 'none';
  border-color: white;
  border-radius: 20px;
}

.react-time-picker inputGroup {
  padding: 40px;
}

.react-time-picker input {
  padding: 10px;
  width: 50px;
  border-radius: 5px;
  border-color: white;
} */

.rc-time-picker input {
  color: black;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-size: 20px !important;
}

.rc-time-picker-panel input {
  font-size: 20px !important;
}

.rc-time-picker-panel-select {
  font-size: 15px !important;
}