.bg-cafegreen {
  background-color: rgb(159, 198, 93)
}

.cafegreen {
  color: rgb(159, 198, 93)
}

.vh-95 { height: 95vh; }
.modal-size {  height:  100vh; }

@media screen and (min-width: 30em) {
  .h-95-ns { height: 95%; }
  .vh-95-ns {  height:  95vh; }
  .modal-size-ns {  height:  95vh; }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .h-95-m { height: 95%; }
  .vh-95-m {  height:  95vh; }
  .modal-size-m {  height:  95vh; }
}

@media screen and (min-width: 60em) {
  .h-95-l { height: 95%; }
  .vh-95-l {  height:  95vh; }
  .modal-l {  height:  95vh; }
}